import React, { useEffect, useState, useMemo } from "react"
import PropTypes from "prop-types"
import Slider from "react-slick"
import cx from "classnames"

import "./ChannelsCarousel.scss"
import { useGuestApi } from "../../hooks/useApi"
import Icon from '../Icon/Icon'

const ChannelsCarousel = ({ isCompact = false }) => {
  const [stationsCarousel, setStationsCarousel] = useState(null);
  const { getStationsCarouselPublication } = useGuestApi()

  const fetchStationsCarousel = () => {
    getStationsCarouselPublication()
      .then(stationsCarousel => {
        setStationsCarousel(stationsCarousel)
      })
      .catch(() => console.log("failed to fetch stations carousel"))
  }

  const openPlayer = url => {
    window.open(url, "_blank", "width=420,height=662")
  }

  const config = useMemo(
    () => ({
      dots: false,
      infinite: false,
      speed: 300,
      slidesToShow: 5,
      slidesToScroll: 1,
      swipeToSlide: false,
      draggable: false,
      responsive: isCompact
        ? [
            {
              breakpoint: 1025,
              settings: {
                slidesToShow: 3,
              },
            },
          ]
        : [
            {
              breakpoint: 769,
              settings: {
                slidesToShow: 1,
              },
            },
            {
              breakpoint: 1025,
              settings: {
                slidesToShow: 2,
              },
            },
            {
              breakpoint: 1300,
              settings: {
                slidesToShow: 4,
              },
            },
          ],
    }),
    [isCompact]
  )

  useEffect(fetchStationsCarousel, [])

  return (
    <div
      className={cx("channels__carousel", {
        "channels__carousel---compact": isCompact,
      })}
    >
      {stationsCarousel &&
        <>
          <h2 className="channels__carousel-title">{stationsCarousel?.title}</h2>
          <Slider {...config}>
            {stationsCarousel.stations.map(({ title, artWork, websiteUrl, thumbnail, color }, index) => (
              <div key={index}>
                <button
                  target="popup"
                  onClick={() => openPlayer(websiteUrl)}
                  className="channels__carousel-item"
                  style={{ color }}
                  rel="noreferrer"
                >
                  {isCompact ? (
                    <div className="channels__carousel-item__logo-wrapper">
                      <img className="channels__carousel-item__logo" src={thumbnail} alt={title} />
                    </div>
                  ) : (
                    <>
                      <span className="channels__carousel-item__artwork" style={{ backgroundImage: `url(${`${artWork}`})` }}>
                        <p hidden className="channels__carousel-item__title">
                          {title}
                        </p>
                      </span>
                      <span className="channels__carousel-item__play">
                        <span className="channels__carousel-item__play-btn">
                          <Icon name="player-play" />
                        </span>
                      </span>
                    </>
                  )}
                </button>
              </div>
            ))}
          </Slider>
        </>
      }
    </div>
  )
}

ChannelsCarousel.propTypes = {
  data: PropTypes.array,
}

export default ChannelsCarousel
