import { useState } from "react"

export function useModal() {
  const [loginModal, setLoginModal] = useState(false)

  function setShowAuthModal(data) {
    setLoginModal(data)
  }
  return {
    loginModal,
    setShowAuthModal,
  }
}
